import React from 'react'
import { s, colors, alpha } from '../../style'
import { T } from '../../typo'
import { WrapperContainer, WrapperSection } from '../../wrappers'
import Img from 'gatsby-image'
import { Grid } from '@material-ui/core'

const AboutHero = ({ theme, data }) => {
  const {
    slug_section,
    title,
    aboutHero_image1,
    aboutHero_image2,
    description,
  } = data

  // console.log(data)

  return (
    <WrapperSection id={slug_section} noPaddingTop>
      <WrapperContainer theme={theme}>
        <Grid container css={sContainer}>
          <Grid item xs={12} sm={12} md={8}>
            <T m={48} d={72} extraCss={sTitle(theme)}>
              {title}
            </T>
            <div css={sBody(theme)}>{description}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} css={{ position: 'relative' }}>
            {aboutHero_image1?.childImageSharp && (
              <div css={sImage1}>
                <Img
                  fluid={aboutHero_image1.childImageSharp.fluid}
                  alt={aboutHero_image1.name}
                  style={sImg}
                />
              </div>
            )}
            {aboutHero_image2?.childImageSharp && (
              <div css={sImage2}>
                <Img
                  fluid={aboutHero_image2?.childImageSharp?.fluid}
                  alt={aboutHero_image2.name}
                  style={sImg}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sContainer = {
  // padding: '0 1.5rem',
  justifyContent: 'space-between',
  height: 'max-content',
}

const sTitle = (theme) => ({
  fontWeight: 800,
  background: `linear-gradient(45deg, ${colors[theme].mainLighter.concat(
    alpha[100]
  )}, ${colors[theme].mainDarker.concat(alpha[100])})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  paddingBottom: '3rem',
  [s.xs]: { paddingBottom: '1.5rem' },
})

const sBody = (theme) => ({
  lineHeight: '2em',
  color: colors[theme].main,
  [s.sm_down]: { paddingBottom: '3rem', fontSize: '0.875rem' },
  [s.sm]: { marginRight: '2rem' },
  [s.lg]: { marginRight: '4rem' },
})

const sImage = {
  position: 'relative',
  borderRadius: '50%',
}

const sImage1 = {
  ...sImage,
  top: 0,
  right: 0,
  width: 360,
  height: 360,
  [s.xs]: { width: `calc(100vw - 6rem)`, height: `calc(100vw - 6rem)` },
}

const sImage2 = {
  ...sImage,
  position: 'absolute',
  right: -32,
  bottom: -32,
  width: 180,
  height: 180,
  [s.xs]: {
    width: `30vw`,
    height: `30vw`,
    position: 'relative',
    top: -128,
    right: 0,
    bottom: 'auto',
  },
  [s.sm_only]: {
    position: 'absolute',
    top: 'auto',
    right: 0,
    bottom: 0,
    width: 360,
    height: 360,
  },
}

const sImg = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: '50%',
}

export default AboutHero
